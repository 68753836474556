
import { SearchPresetType } from '@/types/preset-management/SearchPresetType';
import { reactive, watch } from 'vue';
import { DownOutlined } from '@ant-design/icons-vue';
export default {
  emits: ['dataChange'],
  name: 'PresetSearch',
  props: { presetCount: Number },
  components: {
    DownOutlined,
  },
  setup(props, { emit }) {
    const data = reactive<SearchPresetType>({
      search: '',
      orderBy: 'DESC',
      isEnableCampaign: false,
    });

    const selectOrder = (direction: 'DESC' | 'ASC') => {
      data.orderBy = direction;
    };

    watch(data, () => {
      emit('dataChange', data);
    });
    return { data, selectOrder };
  },
};
