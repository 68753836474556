
import PresetHeader from './PresetHeader.vue';
import PresetSearch from './PresetSearch.vue';
import PresetList from './PresetList.vue';
import PresetItem from './PresetItem.vue';
import AddPreset from '../AddPreset.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { notification } from 'ant-design-vue';
import { SearchPresetType } from '@/types/preset-management/SearchPresetType';
import { ConvertedPresetType } from '@/types/preset-management/PresetType';
import ConfirmModal from '@/components/Modal/ConfirmModal.vue';
import { useStore } from 'vuex';
import NoDataAspect from '@/components/Error/NoDataAspect.vue';
import NoDataFound from '@/components/Error/NoDataFound.vue';
import _ from 'lodash';
import helper from '@/services/helper';

export default {
  components: {
    PresetHeader,
    PresetSearch,
    PresetList,
    PresetItem,
    AddPreset,
    ConfirmModal,
    NoDataAspect,
    NoDataFound,
  },
  emits: ['select'],
  setup(props, { emit }) {
    const showConfirmDelete = ref(false);
    const showAddPreset = ref(false);
    const selectedItemFromStore = computed<ConvertedPresetType | null>(
      () => store.getters['presetFilter/selected']
    );
    const isLoading = computed<boolean>(
      () => store.getters['presetFilter/isLoading']
    );
    const selectedItem = ref<ConvertedPresetType | null>(null);
    const addPresetKey = ref(0);
    const store = useStore();
    const convertedPresets = computed<ConvertedPresetType[]>(
      () => store.getters['presetFilter/convertedPresets']
    );
    const searchData = reactive<SearchPresetType>({
      search: '',
      orderBy: 'DESC',
      isEnableCampaign: false,
    });
    const isSearch = computed<boolean>(() => {
      return (
        searchData.search.trim().length > 0 ||
        searchData.isEnableCampaign === true
      );
    });

    const onAdd = () => {
      addPresetKey.value = new Date().valueOf();
      showAddPreset.value = true;
    };
    const onAdded = async (presetId: string) => {
      await store.dispatch('presetFilter/loadCampaigns');
      await store.dispatch('presetFilter/loadAndSetSelected', presetId);
    };
    const onSearch = _.debounce(async (data: SearchPresetType) => {
      searchData.isEnableCampaign = data.isEnableCampaign;
      searchData.orderBy = data.orderBy;
      searchData.search = data.search.trim();

      const presetName =
        searchData.search.length > 0 ? searchData.search : undefined;
      const isEnableCampaign =
        searchData.isEnableCampaign === true
          ? searchData.isEnableCampaign
          : undefined;
      store.dispatch('presetFilter/setQuery', {
        isEnableCampaign,
        presetName,
        sortDirection: data.orderBy,
      });
      await store.dispatch('presetFilter/loadPresets');
    }, 500);
    const onCopyLink = (preset: ConvertedPresetType) => {
      const link = `${window.location.origin}/share-preset/${preset.preset.id}`;
      helper.copyUrl(link || '');
    };
    const onDelete = async (item: ConvertedPresetType) => {
      selectedItem.value = item;
      showConfirmDelete.value = true;
    };

    const onConfirmDelete = async () => {
      if (selectedItem.value) {
        const res = await store
          .dispatch('presetFilter/deletePreset', selectedItem.value.id)
          .catch(() => {
            notification.error({
              message: `Failed to delete ${truncateText(
                selectedItem.value.preset.presetName
              )}.`,
            });
          });
        if (res) {
          notification.success({
            message: `${truncateText(
              selectedItem.value.preset.presetName
            )} has been deleted successfully.`,
          });
          showConfirmDelete.value = false;

          await store.dispatch('presetFilter/loadCampaigns');
          const item = await store.dispatch('presetFilter/loadAndSetSelected');
          onSelect(item);
        }
      }
    };

    const onFavorite = async (item: ConvertedPresetType) => {
      const favorite = !item.preset.isFavorite;
      item.preset.isFavorite = favorite;
      const res = await store.dispatch('presetFilter/changeState', {
        id: item.preset.id,
        data: { stateChange: favorite, typeChange: 'Favorite' },
      });
      if (res) {
        if (favorite) {
          notification.success({
            message: `${truncateText(item.preset.presetName)} favorited.`,
          });
        } else {
          notification.success({
            message: `${truncateText(
              item.preset.presetName
            )} removed from your favorited.`,
          });
        }
        item = await store.dispatch('presetFilter/loadAndSetSelected', item.id);
        onSelect(item);
      }
    };
    const onSelect = (item: ConvertedPresetType) => {
      selectedItem.value = item;
      emit('select', item);
    };

    const listRef = ref(null);
    const onConfirmClose = () => {
      showConfirmDelete.value = false;
    };

    const handleScroll = async () => {
      // TODO: Handle Lazy load presetFilter/loadPresets
      /** To Resolve adhoc too much call loadPresets
      const element = listRef.value; // Access the DOM element
      if (element) {
        const atBottom =
          element.$el.scrollTop + element.$el.clientHeight >=
          element.$el.scrollHeight;
        if (atBottom) {
          await store.dispatch('presetFilter/loadPresets');
        }
      }*/
    };

    function truncateText(text: string | undefined): string {
      if (text == undefined) {
        return '';
      } else {
        return text.length > 60 ? text.substring(0, 60) + '...' : text;
      }
    }

    onMounted(async () => {
      const item = await store.dispatch('presetFilter/loadAndSetSelected');
      onSelect(item);
    });

    return {
      isLoading,
      isSearch,
      selectedItem,
      onAdd,
      onSearch,
      onCopyLink,
      onDelete,
      onFavorite,
      onSelect,
      showAddPreset,
      addPresetKey,
      onConfirmDelete,
      showConfirmDelete,
      convertedPresets,
      listRef,
      handleScroll,
      onConfirmClose,
      selectedItemFromStore,
      onAdded,
    };
  },
};
